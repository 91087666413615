import React, { FC } from "react";
import { IActionAlertObject } from "../../types/alert";
import styles from "./SimplerAlert.module.css";
import InfoIcon from "../../components/icons/InfoIcon";

interface IProps {
  input: IActionAlertObject;
}

const SimpleAlert: FC<IProps> = ({ input }) => {
  const message = input.message || "";

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <InfoIcon />
      </div>
      <div className={styles.content}>
        <div className={styles.message}>
          {message.length > 150 ? `${message.substring(0, 150)}...` : message}
        </div>
      </div>
    </div>
  );
};

export default SimpleAlert;

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

document.addEventListener("DOMContentLoaded", (event) => {});

document.addEventListener("readystatechange", (event: any) => {
  if (document.readyState === "interactive") {
    const container = document.body.appendChild(document.createElement("div"));
    const root = ReactDOM.createRoot(container);
    root.render(<App />);
  }
});

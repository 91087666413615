import React, { FC, useCallback, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./App.module.css";
import { IActionAlertObject } from "./types/alert";
import { ILoaderConfig, ILoaderRequest } from "./types/loader";
import { GENERAL, ACTIONABLE, SIMPLE } from "./constants";
import ActionAlert from "./components/ActionAlert/ActionAlert";
import CloseIcon from "./components/icons/CloseIcon";
import SimpleAlert from "./components/SimpleAlert/SimplerAlert";
import Loader from "./components/Loader";
import DOMPurify from "dompurify";

interface IPostMessage {
  messageType: "SHOW_ALERT_NOTIFICATION";
  payload: {
    alertType?: "success" | "error" | "info" | "warn";
    message?: string;
    __html?: boolean;
  };
}

const params = new URLSearchParams(window.location.search);

const CloseButton = ({ closeToast }: any) => {
  return (
    <span onClick={closeToast} className={styles.close}>
      <CloseIcon />
    </span>
  );
};

const App: FC = () => {
  const [loaderRequests, setLoaderRequests] = useState<ILoaderRequest[]>([]);

  const postMessageHandler = useCallback((e: MessageEvent<IPostMessage>) => {
    if (e.data?.messageType === "SHOW_ALERT_NOTIFICATION") {
      const shouldPropogate = params.get("suppress_alerts") !== "true";
      const isChildFrame = window.self !== window.top;

      if (shouldPropogate && isChildFrame) {
        window.parent.postMessage(e.data, "*");
      } else {
        const { alertType, message, __html } = e.data.payload;
        let sanitizedMessage: string | JSX.Element = message || "";
        if (__html && message) {
          sanitizedMessage = (
            <div
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }}
            />
          );
        }
        switch (alertType) {
          case "error":
            toast.error(sanitizedMessage, { containerId: GENERAL });
            break;
          case "info":
            toast.info(sanitizedMessage, { containerId: GENERAL });
            break;
          case "success":
            toast.success(sanitizedMessage, { containerId: GENERAL });
            break;
          case "warn":
            toast.warn(sanitizedMessage, { containerId: GENERAL });
            break;
          default:
            toast(sanitizedMessage, { containerId: GENERAL });
            break;
        }
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", postMessageHandler);
    return () => {
      window.removeEventListener("message", postMessageHandler);
    };
  }, [postMessageHandler]);

  useEffect(() => {
    Object.defineProperty(window, "showActionAlert", {
      value: (input: IActionAlertObject) => {
        const content = <ActionAlert input={input} />;
        toast(content, {
          containerId: ACTIONABLE,
          toastId: input.id,
          onClose: () => {
            try {
              (window as any).J36.Notification.DeleteNotification(input.id);
            } catch (e) {
              console.error(e);
            }
          },
        });
      },
      configurable: true,
    });

    Object.defineProperty(window, "deleteActionAlert", {
      value: (id: any) => {
        toast.dismiss({ containerId: ACTIONABLE, id });
        toast.dismiss({ containerId: SIMPLE, id });
      },
      configurable: true,
    });

    Object.defineProperty(window, "showAlert", {
      value: (input: IActionAlertObject) => {
        const content = <SimpleAlert input={input} />;
        toast(content, {
          containerId: SIMPLE,
          toastId: input.id,
          onClose: () => {
            try {
              (window as any).J36.Notification.DeleteNotification(input.id);
            } catch (e) {
              console.error(e);
            }
          },
        });
      },
      configurable: true,
    });
  }, []);

  const showLoaderHandler = useCallback(
    (input: ILoaderConfig) => {
      let appMgr: Window | null = null;
      try {
        appMgr = window.getAppWindow();
      } catch (e: any) {
        console.error("App Viewer not accessible", e.message);
      }
      if ((appMgr && appMgr === window) || window.self === window.top) {
        const foundIndex = loaderRequests.findIndex((r) => {
          return (
            r.appType === input.appType &&
            r.appSubType === input.appSubType &&
            r.id === input.id
          );
        });
        const _requests: ILoaderRequest[] = JSON.parse(
          JSON.stringify(loaderRequests)
        );
        if (foundIndex < 0) {
          _requests.push({
            ...input,
            loading: true,
          });
          setLoaderRequests(_requests);
        }
      } else {
        try {
          window.parent.showGlobalLoader(input);
        } catch {
          window.parent.parent.showGlobalLoader(input);
        }
      }
    },
    [loaderRequests]
  );

  const hideLoaderHandler = useCallback(
    (input: ILoaderConfig) => {
      let appMgr: Window | null = null;
      try {
        appMgr = window.getAppWindow();
      } catch (e: any) {
        console.error("App Viewer not accessible", e.message);
      }

      if ((appMgr && appMgr === window) || window.self === window.top) {
        const foundIndex = loaderRequests.findIndex((r) => {
          return (
            r.appType === input.appType &&
            r.appSubType === input.appSubType &&
            r.id === input.id
          );
        });
        const _requests: ILoaderRequest[] = JSON.parse(
          JSON.stringify(loaderRequests)
        );
        if (foundIndex >= 0) {
          _requests.splice(foundIndex, 1);
          setLoaderRequests(_requests);
        }
      } else {
        try {
          window.parent.hideGlobalLoader(input);
        } catch {
          window.parent.parent.hideGlobalLoader(input);
        }
      }
    },
    [loaderRequests]
  );

  useEffect(() => {
    window.showGlobalLoader = showLoaderHandler;
  }, [showLoaderHandler]);

  useEffect(() => {
    window.hideGlobalLoader = hideLoaderHandler;
  }, [hideLoaderHandler]);

  const loaderConfig = loaderRequests[0];

  return (
    <>
      {loaderConfig ? <Loader config={loaderConfig} /> : null}
      <ToastContainer
        stacked
        className={styles.alerts_container}
        position="bottom-right"
        containerId={GENERAL}
        limit={2}
        closeButton={CloseButton}
        bodyClassName={styles.alert_body}
      />
      <ToastContainer
        stacked
        className={styles.alerts_container}
        position="bottom-right"
        containerId={SIMPLE}
        limit={2}
        closeButton={CloseButton}
        autoClose={false}
      />
      <ToastContainer
        stacked
        className={styles.actions_container}
        position="bottom-right"
        containerId={ACTIONABLE}
        autoClose={false}
        closeOnClick={false}
        hideProgressBar
        limit={2}
        closeButton={CloseButton}
      />
    </>
  );
};

export default App;
